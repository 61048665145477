<template>
  <b-card
    v-if="account_type === '1'"
    :class="[style['advertisement'], 'mb-4']"
  >
    <b-img
      v-if="isVideo === false"
      class="w-100 ad-image"
      :src="advImage"
      alt="Advertisement"
      fluid
    />
    <video
      v-else
      id="ad-video"
      style="width: 100%"
      autoplay
      muted
      loop
    >
      <source
        :src="advImage"
        type="video/mp4"
      >
    </video>
    <b-card-text class="font-weight-bold pl-3 pt-3 mb-0 text-truncate">
      {{ advText }}
    </b-card-text>
    <div class="text-right">
      <b-button
        variant="default"
        class="font-weight-light font-open-sans shadow-none"
        @click="handleRedirection(advId)"
      >
        See more &gt;
      </b-button>
    </div>
  </b-card>
</template>
<script>
import style from "../../styles/feed.module.scss";
import { advertisementStats, getAdsDetail } from '../../api/feed';
export default 	{
    props: {
        advImage: {
            required: true,
            type: String
        },
        advText: {
            required: true,
            type: String
        },
        advId:{
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            style,
            account_type: localStorage.getItem('account-type'),
            isVideo:false
        };
    },
    created(){
        if (this.advImage.includes('.mp4')){
            this.isVideo = true;
        } else {
            this.isVideo = false;
        }
    },
    methods:{
        async handleRedirection(id){

            // Call advertisement stats API
            let statsType = 1;
            await advertisementStats(id,statsType);
            let adDetails = await getAdsDetail(id);
            if (Object.keys(adDetails).length > 0 && adDetails.url !== null){
                window.open(adDetails.url, "_blank");
            } else {
                window.location.href = `/ads/${window.btoa(id)}`;
            }
        }
    }
};
</script>