//File is used for API calls only
import { APIUrlList } from "../js/api_url";
import { _sendResponse } from "../js/common-function";
import { apiCall } from "../js/common-function";
import moment from "moment-timezone";

export const advertisementsList = async (id = null) => {
    try {
        const response = await apiCall(
            APIUrlList.GETADSLIST + `?excluded=${id}`,
            null,
            "GET",
            true
        );
        var array = response.data.data;
        const newAds = [];
        if (array) {
            array.map((value) => {
                var obj = {};
                obj["advId"] = value.id;
                obj["advText"] = value.title;
                obj["advImage"] = value.media_file_url;
                obj["advUrl"] = value.url;
                newAds.push(obj);
            });
        }
        return newAds;
    } catch (error) {
    // handle authentication and validation errors here
        _sendResponse("error", error);
    }
};

export const getAdsDetail = async (id = null) => {
    try {
        if (id !== null) {
            const response = await apiCall(
                APIUrlList.GETADSDETAILS + `?advertisement_id=${id}`,
                null,
                "GET",
                true
            );
            return response.data.data;
        }
        return null;
    } catch (error) {
        _sendResponse("error", error);
    }
};

export const getUpcomingEventList = async (fabId) => {
    try {
        var formData = {
            fab_id: fabId,
        };
        let response = await apiCall(
            APIUrlList.GETFABUPCOMINGEVENT,
            formData,
            "POST",
            true
        );
        return await response.data.data;
    } catch (error) {
        _sendResponse("error", error);
    }
};

export const advertisementStats = async (id = null, statsType) => {
    var formData = {
        adv_id: id,
        type: statsType,
    };
    await apiCall(APIUrlList.SENDADSINFO, formData, "POST", true);
    return null;
};

export const getUserProfile = async (username) => {
    try {
        let response = await apiCall(
            APIUrlList.PROFILEDETAILS + "/" + username,
            null,
            "GET",
            true
        );
        return await response.data.data;
    } catch (error) {
        _sendResponse("error", error);
    }
};

export const getLiveStreamingList = (fabId) => {
    try {
        var formData = {
            fab_id: fabId,
        };
        let response =  apiCall(
            APIUrlList.LIVESTREAMLIST,
            formData,
            "POST",
            true
        );
        var array = response.data.data;
        const streaming = [];
        if (array) {
            array.map((val) => {
                var obj = {};
                obj["id"] = val.id;
                obj["stream_type"] =
          val.stream_type === 1
              ? "Private"
              : val.stream_type === 2
                  ? "Public"
                  : "";
                //var start_date_time = moment(val.date).format('YYYY-MM-DD')+' '+val.start_time;
                var start_date_time = moment(val.start_time).format(
                    "YYYY-MM-DD HH:mm:ss"
                );
                var start_date = new Date(start_date_time.replace(/-/g, "/"));
                start_date = start_date.toString();
                //var end_date_time = moment(val.date).format('YYYY-MM-DD')+' '+val.end_time;
                var end_date_time = moment(val.end_time).format("YYYY-MM-DD HH:mm:ss");
                var end_date = new Date(end_date_time.replace(/-/g, "/"));
                end_date = end_date.toString();
                obj["start"] = moment(start_date).format("YYYY-MM-DD HH:mm:ss");
                obj["end"] = moment(end_date).format("YYYY-MM-DD HH:mm:ss");
                obj["timezone"] = val.time_zone;
                streaming.push(obj);

                var Sdate = new Date(val.start_time).toString();
                //let original_start_date = changeTimezone(Sdate, val.time_zone);
                obj["start_original_time"] = moment(Sdate).format("hh:mm a");
                obj["start_original_date"] = moment(Sdate).format("DD-MMM-yyyy");
                obj["day"] = moment(Sdate).format("dddd");
                var Edate = new Date(val.end_time).toString();
                //let original_end_date = changeTimezone(Edate, val.time_zone);
                obj["end_original_time"] = moment(Edate).format("hh:mm a");

                let date1 = new Date();
                let date2 = new Date(start_date);
                let date3 = new Date(end_date);
                let remainig = showDays(date1, date2, date3);
                obj["remaining_time"] = remainig !== null ? remainig : [];
                obj["call_status"] = val.status;
                obj["fab"] = val.fab;
                obj["booking"] = val.booking;
            });
        }
        return streaming;
    } catch (error) {
        _sendResponse("error", error);
    }
};

export function showDays(date1, date2, endDate) {
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
    let date3 = moment(date1).format("DD-MM-YYYY HH:mm:ss");
    let date4 = moment(date2).format("DD-MM-YYYY HH:mm:ss");

    var startTime = moment(date3, "DD-MM-YYYY hh:mm:ss");
    var endTime = moment(date4, "DD-MM-YYYY hh:mm:ss");
    var hoursDiff = endTime.diff(startTime, "hours");
    var minsDiff = endTime.diff(startTime, "minutes");
    var sec = moment.utc(endTime.diff(startTime)).format("HH:mm:ss");

    //time left for call to end
    var date5 = moment(endDate).format("DD-MM-YYYY HH:mm:ss");
    var callEndTime = moment(date5, "DD-MM-YYYY hh:mm:ss");
    var call_secs_left = callEndTime.diff(startTime, "seconds");
    return {
        leftDays: `${days} ${days === 1 ? "day" : "days"}`,
        days: days,
        hours: hoursDiff,
        minutes: minsDiff,
        time_left: sec,
        call_duration_left: call_secs_left,
    };
}
